.ftr{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 400px;
    padding: 2em 0;
}
.ftr ul, .ftr ol{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin: 0 auto;
}
.ftr ol li{
    margin: 0 2vw;
}
.ftr ul li{
    margin: 0 1.5em;
}
.ftr ol li:hover a, .nav_route{
    color: #A6A28D;
}
.ftr ul li img{
    width: 25px;
    height: 25px;
}
.ftr ul li:hover img{
    filter: brightness(500%) contrast(500%) saturate(100%);
}
.ftr img:nth-child(3){
    width: 200px;
    height: 130px;
}
.ftr h4{
    font-family: 'Roboto';
}

/* MOBILE */
@media screen and (max-width:992px){
    .ftr ol{
        height: 100%;
        flex-direction: column;
        margin-bottom: 2em;
    }

    .ftr ol li{
        margin: 1.5em 0;
    }
    .ftr h4{
        white-space: pre-wrap;
        text-align: center;
        line-height: 30px;
    }
}