.story{
    background: #fff;
}
.discover{
    width: 100%;
    height: 950px;
    background: url('/src/assets/images/backgrounds/bg_story.webp') no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.story .rotate{
    background: url('/src/assets/images/misc/n_circle.svg') no-repeat center;
    background-size: contain;
    z-index: 0;
}
.discover img{
    position: relative;
    width: 433px;
    height: 230px;
}
.discover div:last-child{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50px);
}
.discover h1{
    font-size: 32px;
    color: #A6A28D;
    margin: 0em 0 2em 0;
}
.discover a{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20.2368px;
    color: #A6A28D;
    position: relative;
    cursor: pointer;
}
.discover a img{
    width: 25px;
    height: 15px;
    margin-top: 1em;
}
/* ----- */
.age{
    min-height: 1300px;
    background:  #0D1315;
    box-shadow: 0px 6.36399px 3.18199px rgba(0, 0, 0, 0.25);
}
.f-cov{
    width: 100%;
    height: 100%;
}
.age.a0 .f-cov{
    background: url('/src/assets/images/backgrounds/f_age_circle.svg') no-repeat center;
    background-size: contain;
}
.age.a1 .f-cov{
    background: url('/src/assets/images/backgrounds/s_age_circle.svg') no-repeat center;
    background-size: contain;

}
.age.a2 .f-cov{
    background: url('/src/assets/images/backgrounds/t_age_circle.svg') no-repeat center;
    background-size: contain;

}
.age .age-img{
    width: 100%;
    height: 25vw;
    position: relative;
    text-align: center;
}
.age.a0 .age-img{
    background: url('/src/assets/images/age_bg_f.png') no-repeat;
    background-size: cover;
}

.particles{
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat:repeat;
    background-attachment: scroll;
    animation: slideshow 7s forwards infinite;
    z-index: 1;
}

@keyframes slideshow
    {
      0% {top:-15%;}
      50%{top: 0%;}
      100% {top:-15%;}
    }


    .age.a0 .particles{
        background:url('/src/assets/images/particles-red.png');

        background-size: contain;
    }
    .age.a1 .particles{
        background:url('/src/assets/images/particles-blue.png');
        background-size: cover;
    }
    .age.a2 .particles{
        background:url('/src/assets/images/particles-green.png');
        background-size: cover;
    }
.age.a1 .age-img{
    background: url('/src/assets/images/age_bg_s.png') no-repeat;
    background-size: cover;
}
.age.a2 .age-img{
    background: url('/src/assets/images/age_bg_t.png') no-repeat;
    background-size: cover;
}
.age.a1 .age-img img{
    transform: translateY(-50px);
}
.age.a2 .age-img img{
    transform: translateY(-70px);
}
.age-img img{
    height: 100%;
}
.age section{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.age section ol{
    width: 20%;
}
.age section ul .year{
    width: 330px;
    height: 45px;
    background: url('/src/assets/images/misc/year.svg') no-repeat left;
    margin: 2em 0;
    line-height: 45px;
    font-family: 'Colus';
    font-weight: 400;
    font-size: 14px;
    color: #A6A28D;
    padding-left: 6em;
    cursor: pointer;
}
.age.a0 section ul .year:hover, .age.a0 section ul .year.y0{
    background: url('/src/assets/images/misc/year_hf.svg') no-repeat center;
}
.age.a1 section ul .year:hover, .age.a1 section ul .year.y1{
    background: url('/src/assets/images/misc/year_hs.svg') no-repeat center;
}
.age.a2 section ul .year:hover, .age.a2 section ul .year.y2{
    background: url('/src/assets/images/misc/year_ht.svg') no-repeat left;
    transform: scale(1.1);
}
.paper{
    min-width: 641px;
    width: 641.66px;
    height: 820px;
    background: url('/src/assets/images/misc/paper.svg') no-repeat;
    background-size: cover;
    transform: translateY(-150px);
    padding: 10em 0;
}
.paper-cont{
    width: 60%;
    margin: 0 auto;
}
.paper-cont h1{
    font-family: "L'Elf Noir du Mal";
    font-size: 42.2097px;
    text-transform: uppercase;

    text-align: center;
}
.age.a0 .paper-cont h1{
    color: #FF845E;
    text-shadow: 0px 0px 100px #FF845E;
}
.age.a1 .paper-cont h1{
    color: #78D1F9;
    text-shadow: 0px 0px 100px #67F6FF;
}
.age.a2 .paper-cont h1{
    color: #24BE4E;
    text-shadow: 0px 0px 100px #6BFF68;
}
.paper-cont h1 span{
    font-family: 'Colus';
    font-size: 18.0899px;
    text-shadow: 0px 0px 30px #FF845E;
}
.paper-cont > div{
    width: 100%;
    justify-content: space-between;
    padding: 2em 0;
}
.paper-cont div h4{
    color: #A6A28D;
}
.paper-cont div button{
    font-family: 'Roboto';
    font-size: 14px;
    color: #FF845E;
}
.paper-cont div button img{
    margin-left: 1em;
    filter: invert(60%) sepia(78%) saturate(804%) hue-rotate(360deg) brightness(80%) contrast(100%);
}
.paper-cont p{
    font-family: 'Roboto';
    line-height: 20px;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 15; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.timeline{
    position: absolute;
    top: 32%;
    right: 10%;
}
.age.a0 #progress-bar-container li, .age.a0 #progress-bar-container li.active::after{
    border: 1px solid #FF845E;
}
.age.a0 #progress-bar-container li:hover,.age.a0 #progress-bar-container li.active,.age.a0 #progress-bar-container #line-progress0 {
    background: #FF845E;
}
.age.a1 #progress-bar-container li, .age.a1 #progress-bar-container li.active::after{
    border: 1px solid #78D1F9;
}
.age.a1 #progress-bar-container li:hover,.age.a1 #progress-bar-container li.active,.age.a1 #progress-bar-container #line-progress1 {
    background: #78D1F9;
}
.age.a2 #progress-bar-container li, .age.a2 #progress-bar-container li.active::after{
    border: 1px solid #24BE4E;
}
.age.a2 #progress-bar-container li:hover,.age.a2 #progress-bar-container li.active,.age.a2 #progress-bar-container #line-progress2 {
    background: #24BE4E;
}
/* ---MINI STORY---- */
.age .mini-story{
    display: none;
}
@media screen and (max-width:992px){
    #desk-story{
        display: none;
    }
    .age .age-img{
        height: 382px;
    }

    .age .age-img img{
        width: 350px;
        height: 350px;
    }

    .age.a1 .age-img img,.age.a2 .age-img img{
        transform: none;
    }

    .age .mini-story{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .age .mini-story ul{
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;
    }

    .age .mini-story ul li{
        margin: 1em 0;
    }

    .age .mini-story p{
        width: 350px;
        font-family: 'Roboto';
        line-height: 22px;
        color: #FFFFFF;
    }
    .age .mini-story .paper{
        min-width: fit-content;
        width: 244px;
        height: 300px;
        background-size: cover;
        margin-left: 0;
        transform: translateY(-50px);
        padding: 5em 0;
    }

    .age .mini-story .timeline{
            top: 100%;
            right: -7em;
    }
}