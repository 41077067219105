  .notify{
    position: fixed;
    min-width: 300px;
    height: 50px;
    right: 1%;
    bottom: 5%;
    transition: transform 0.3s ease-out;
    animation: slideIn 0.3s ease-in-out;
    z-index: 9;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    display: none;
    padding: 0 2em;
  }
  .notify.active{
    display: flex;
  }

  .notify img{
    width: 12px;
    height: 12px;
    filter: invert(100%) sepia(4343%) hue-rotate(232deg) brightness(116%) contrast(105%);
    margin-left: 1em;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(0%);
    }
  }

  .notify h3{
    font-family: Roboto;
  }

  @media (max-width: 728px) {
    .snackbar-container {
      width: auto;
    }
  }
  
 