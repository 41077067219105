.age_c{
    width: 100%;
    min-height: 1000px;
}
.age_card{
    width: 90%;
    min-height: 750px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: url('/src/assets/images/backgrounds/bg_story.webp') no-repeat center;
    background-size: cover;
}

.age_img{
    width: 666px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
}
.age_img div{
    width: 381px;
    height: 783px;
    object-fit: contain;
    transform: translateY(-3vw) scale(0.9);
}
.age_img span{
    text-align: center;
    line-height: 70px;
    width: 100%;
    height: 80px;
    border-radius: 4px;
    clip-path: polygon(52% 0, 100% 0, 100% 57%, 50% 100%, 0 57%, 0 0);
    background: linear-gradient(180deg, #15212D 0%, rgba(21, 33, 45, 0.8) 100%);
    box-shadow: 0px 0px 24.025px rgba(217, 217, 217, 0.1);
    background: linear-gradient(180deg, #20283F 0%, #2B8243 100%);
    box-shadow: 0px 0px 30px rgba(217, 217, 217, 0.25); 
}


.age_det{
    flex-grow: 1;
    width: 1000px;
    height: 800px;
    min-height: 750px;
    background: linear-gradient(180deg, #030203 0%, rgba(3, 2, 3, 0.6) 100%);
    border-radius: 3.06493px;
    clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.age_det ul{
    width: 745px;
    max-width: 90vw;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.age_det ul li{
    width: 743px;
    max-width: 100%;
    max-height: 100%;
    border: 0.79px solid;
    border-image: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #D9D9D9 100%) 1;
    margin: 2em 0;
    padding: 0em 3em;
    font-family: 'Roboto';
}

.age_det h4{
    line-height: 30px;
    color: #A6A28D;
    padding: 0.5em 0;
}

.age_det span{
    font-size: 14px;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.05em;
    margin: 0 1em 1em;
    white-space: pre-wrap;
    transition: all .1s ease;
    height: 0;
    overflow: hidden;
    display: inherit;
    transition: all 1s ease;
}
.age_det ul li:hover span{
    height: 100%;
}

/* MOBILE */
@media screen and (max-width:1800px){
    .age_det{
        clip-path: none;
    }
}
