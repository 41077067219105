.sec{
    width: 100%;
    max-width: 100vw;
    min-height: 1000px;
}
.sec.a{
    background: url('/src/assets/images/backgrounds/land_bg.webp') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: land 1s forwards;
}

@keyframes land{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;

    }
}
.subscibe{
    max-width: 90vw;
    margin-bottom: 17rem;
}
.subscibe h2{
    margin: 2rem auto;
    line-height: 25px;
    color: #CBD7D9;
    font-size: calc(.6em + .4vh + .4vw);
}
.subscibe input{
    width: 450px;
    max-width: 85%;
    height: 45px;
    border: none;
    background: #CACACA;
    border-radius: 10px;
    color: #4F5052;
    padding: 0 2em;
    margin-bottom: 1rem;
    transform: skew(-10deg);
    font-size: 14px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}
.subscibe input:focus{
    outline: #d9d9d9 solid 1px;
}
.subscibe button{
    width: 200px;
    height: 45px;
    background: rgba(3, 2, 3, 0.8);
    transform: skew(-10deg);
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    color: #A6A28D;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-size: 14px;
}
/* --- */
.sec.b{
    background: url('/src/assets/images/backgrounds/secb.webp') no-repeat;
    background-size: cover;
}
.b-desc{
    max-width: 100vw;
    height: 700px;
    margin: 0 auto;

}
.b-desc p{
    font-family: 'Inter';
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    padding: 1em 0;
    margin: 3em 0;
    width: 700px;
    max-width: 90vw;
}
.card ul{
    padding-top: 4vw;
}
.card ul li{
    width: 462px;
    height: 80px;
    background: url('/src/assets/images/misc/year.svg') no-repeat;
    background-size: 100% 100%; 
    margin: 2em 2em;
    display: flex;
    align-items: center;
    padding-left: 7em;
}
.card ul li:hover{
    background: url('/src/assets/images/misc/year_hs.svg') no-repeat;
    background-size: 100% 100%; 
}
.card ul li img{
    width: 35px;
    height: 30px;
    margin-right: 1em;
}

.b-desc ol{
    width: 100vw;
    flex-wrap: wrap;
}
.b-desc ol li{
    width: 100px;
    max-width: 15vw;
    max-height: 15vw;
    height: 100px;
    background: url('/src/assets/icons/frame_soc.png') no-repeat center;
    background-size: 100% 100%;
    user-select: none;
    margin: 0 2vw;
    position: relative;
    z-index: 2;

}

.b-desc ol li img{
    position: relative;
    width: 20px;
    height: 20px;
    
}
.b-desc ol li:hover{
    background: url('/src/assets/icons/hover_soc.png') no-repeat center;
    background-size: cover;
}

.b-desc ol li:hover img{
    filter: brightness(500%) contrast(500%) saturate(100%);
}


.b-desc ol li a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ------ */
.sec.c{
    min-height: 100vh;
    height: 950px;
    background: url('/src/assets/images/backgrounds/bg_story.webp') no-repeat center;
    background-size: cover;
}
.sec.c section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 90%;
}
.rotate{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('/src/assets/images/misc/circle.svg') no-repeat center;
    z-index: 0;
    animation: rot 100s infinite linear;
}
@keyframes rot{
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(359deg);
    }
}
.sec.c section ul{
    position: relative;
    display: flex;
    align-items: center;
}
.sec.c ul li, .sec.c ol li{
    width: 240px;
    height: 480px;
    margin: 0 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('/src/assets/images/misc/age_frame.png') no-repeat center;
    background-size: cover;

}
.sec.c ul li div, .sec.c ol li div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sec.c ul li:nth-child(1):hover{
    filter: drop-shadow(0px 24.025px 50px rgba(130, 63, 43, 0.5));
}
.sec.c ul li:nth-child(2):hover{
    filter: drop-shadow(0px 24.025px 50px rgba(54, 110, 134, 0.5));
}
.sec.c ul li:nth-child(3):hover{
    filter: drop-shadow(0px 24.025px 50px rgba(43, 130, 67, 0.5));
}
.sec.c ul li:nth-child(1) div{
    background: url('/src/assets/images/misc/first_h.png');
    background-size: cover;
}
.sec.c ul li:nth-child(2) div{
    background: url('/src/assets/images/misc/second_h.png');
    background-size: cover;
}
.sec.c ul li:nth-child(3) div{
    background: url('/src/assets/images/misc/third_h.png');
    background-size: cover;
}
.sec.c ul li span, .sec.c ol li span{
    text-align: center;
    width: 240px;
    height: 107px;
    clip-path: polygon(52% 0, 100% 0, 100% 57%, 50% 100%, 0 57%, 0 0);
    background: linear-gradient(180.4deg, #0D1315 0.34%, #000000 98.07%);
    box-shadow: 0px 6.36399px 3.18199px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
}
.sec.c ul li:nth-child(1):hover span{
    background: linear-gradient(180deg, #20283F 0%, #823F2B 100%);
    box-shadow: 0px 0px 30px rgba(217, 217, 217, 0.25);
}
.sec.c ul li:nth-child(2):hover span{
    background: linear-gradient(180deg, #20283F 0%, #2B5882 100%);
    box-shadow: 0px 0px 30px rgba(217, 217, 217, 0.25);
}
.sec.c ul li:nth-child(3):hover span{
    background: linear-gradient(180deg, #20283F 0%, #2B8243 100%);
    box-shadow: 0px 0px 30px rgba(217, 217, 217, 0.25); 
}
.sec.c ul li span h4, .sec.c ol li span h4{
    letter-spacing: 0.1em;
    color: #A6A28D;
    font-family: "L'Elf Noir du Mal";
    font-size: 22px;
    color: #A6A28D;
    text-shadow: 0px 0px 52.2974px #D9D9D9;
    line-height: 30px;
}

.sec.c ul li:nth-child(1) span h4,.sec.c ul li:nth-child(1) span h5{
    color: #FF845E;
}
.sec.c ul li:nth-child(2) span h4,.sec.c ul li:nth-child(2) span h5{
    color: #78D1F9;
}
.sec.c ul li:nth-child(3) span h4,.sec.c ul li:nth-child(3) span h5{
    color: #24BE4E;
}

.sec.c section h2{
    font-size: 24px;
    color: #A6A28D;
}
.sec.c section a{
    display: block;
    width: 240px;
    padding: 1em 0;
    background: rgba(3, 2, 3, 0.8);
    border-radius: 10px;
    transform: skew(-10deg);
    text-align: center;
    border-bottom: 1px solid;
    color: #A6A28D;
}
.sec.c section a:hover{
    transform: scale(1.1);
}
.sec.c ol {
    display: none;
}
.sec.c i{
    cursor: pointer;
    user-select: none;
    display: none;
    position: relative;
}
.sec.c i img{
    transform: scale(1.5);
    filter: invert(73%) sepia(14%) saturate(362%) hue-rotate(13deg) brightness(87%) contrast(84%);
}
.sec.c i:first-child{
    transform: rotateZ(180deg);
}
.sec.c i:active img{
    transform: scale(2.5);
}
.sec.c ol li{
    animation: age 1s ease forwards;
}
@keyframes age{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
/* --------------- */
.sec.d{
    padding: 5em 0;
}
.sec.d h2{
    text-align: center;
    font-size: 24px;
    color: #A6A28D;
}
/* ---------------- */
.sec.e{
    min-height: 908px;
    display: flex;
    justify-content: center;
    align-items: center;
    

}
.sec.e .youtube{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.678);
    z-index: 1;
    

}

.sec.e iframe{
    width: 800px;
    height: 600px;
    position: absolute;
    z-index: 10;
    max-width: 90vw;
    max-height: 60vw;
    

}
.sec.e .vid{
    position: relative;
    width: 95%;
    min-height: 741px;
    display: flex;
    justify-content: space-between;

}
.vid video, .vid img:nth-child(1){
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.vid_cont{
    width: 1000px;
    max-width: 100vw;
    min-height: 741px;
    background: linear-gradient(180deg, rgba(3, 2, 3, 0.9) 0%, rgba(3, 2, 3, 0.54) 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 5rem;
    clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
}
.vid_cont button{
    padding: .8em;
    filter: drop-shadow(0px 0px 23.75px rgba(217, 217, 217, 0.25));
    border-radius: 7.91667px;
    font-size: 16px;
    color: #D9D9D9;
    box-shadow: 0px 0px 23.75px 0px #D9D9D940;

    /* border: 0.79px solid; */
}
.vid_cont p{
    font-family: "Inter";
    font-weight: bold;
    line-height: 30px;
    padding-right: 30em;
}
.vid_cont h2{
    font-size: 24px;
    color: #A6A28D;
}
.btns{
    position: relative;
    padding: 2em;
}
.btns button{
    position: relative;
    width: 50px;
    height: 50px;
    background: rgba(3, 2, 3, 0.9);
    box-shadow: 0px 10px 30px rgba(217, 217, 217, 0.25);
    margin: 2vh 0;
}
.vid .btns button img{
    width: 22px;
    height: 22px;
}
/* --------------- */

.sec.f{
    display: flex;
    

}
.coe{
    width: 50%;
    background: url('/src/assets/images/misc/coe.webp') no-repeat right;
    background-size: cover;
    

}
.ag{
    width: 50%;
    background: url('/src/assets/images/misc/ag.webp') no-repeat center;
    background-size: cover;
}
.sec.f img{
    transform: scale(1.5);
}
.sec.f section{
    width: 50%;
    height: 1000px;
    padding: 3em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.coe section{
    float: left;
   
}
.ag section{
    float: right;
}
.sec.f section a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 45px;
    box-shadow: 0px 0px 30px 0px #D9D9D940;
    border: 1px solid;
	transform: skewX(-10deg);
    font-size: calc(0.6em + 0.1vw + 0.1vh);
    margin-bottom: 5em;
    border-radius: 10px;
}
.sec.f section a:hover,.sec.f section a:active{
    transform: scale(1.1);
}
.sec.f section a img{
    height: 100%;
    transform: scale(1.5);
}
.sec.f section h3{
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    padding: 0 3em;
}
.ag section h3{
    text-align: right;
}
.coe ul{
    margin-top: 2em;
}
.coe ul li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.coe ul li img{
    width: 50px;
    margin: 1em 4rem;
}
.coe ul li h4{
    font-size: 14px;
}

/* --------- */

@media screen and (max-width:992px){
    .sec.a{
        background-position: -11em -2em;
    }

.subscibe h2{
    color: #fff;    
}
    .sec.b .card{
        width: 100%;
    }
    .sec.f{
        flex-direction: column;
    }
    .coe,.ag{
        width: 100%;
        min-height: 120vh;
    }
    .sec.f section{
        width: 100%;
    }
    .vid{
        flex-direction: column;
        align-items: center;
    }
    .vid_cont{
        clip-path: none;

    }
    .vid_cont img{
        width: 100%;
    }
    .vid_cont p{
        padding-right: 0em;
    }
    .btns{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    /* ------- */
    .sec.c section ul{
        display: none;
    }
    .sec.c ol{
        display: flex;
        align-items: center;
    }
    .sec.c i{
        display: block;
    }
    .sec.c ul li span h4, .sec.c ol li span h4{
        color: #CBD7D9;
    }
    /* ---------- */
    .sec.d ul li{
        opacity: 1;
        transform: translateY(0px);
        margin: 2vh 0;
    }
   .sec.d ul li video{
        transform: scale(1);

    }
    .sec.d ul li p{
        color: #fff;
    }
    .rotate{
        width: 60vw;
        left: 20%;
        height: 50%;
        position: absolute;
        background-size: 100% 100%;
        margin-top: 25vh;
    }
}