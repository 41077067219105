
/* --- Start progress bar --- */

#progress-bar-container {
    width: 50px;
    margin: auto;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#progress-bar-container ul {
    height: 300px;
    position: relative;
	z-index:2;
	width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#progress-bar-container li {
	position:relative;
    width:15px;
	height:15px;
	transition:all ease 0.9s;
    background: transparent;
    transform:rotate(45deg);
	cursor:pointer;
    box-shadow: 0px 0px 30px rgba(107, 255, 104, 0.5);
}
#progress-bar-container li.active::after {
	content: "";
	position: absolute;
	display: block;
	right: 0;
	bottom: -9px;
	left: -9px;
	margin: auto;
	width: 29px;
	height: 29px;
	transform: rotate(45deg);
}

#progress-bar-container .line {
	width:3px;
	margin:auto;
	height:290px;
	position:absolute;
	z-index:1;
}
#line-progress {
	content:" ";
    width: 3px;
	position:absolute;
	z-index:2;
	border-radius:50px;
}

#progress-content-section .section-content {
	display:none;
	animation: FadeInUp 700ms ease 1;
	animation-fill-mode:forwards;
	transform:translateY(15px);
	opacity:0;
}
#progress-content-section .section-content.active {
	display:block;
}
@keyframes FadeInUp {

	0% {

		transform:translateY(15px);

		opacity:0;

	}
	100% {

		transform:translateY(0px);

		opacity:1;

	}
}
