* {
    margin: 0;
    letter-spacing: 0.08em;
    box-sizing: border-box;
    transition: all 300ms ease;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}
html{
    overflow-x: hidden;
}

body {
    background-color: #030203;
    color: #d9d9d9;
    font-family: "Colus";
    overflow-x: hidden;
}
#logo{
    width: 100vw;
    height: 100vh;
}
#logo img{
    transform: translate(0vh, -70px);
}

ul,ol {
    padding: 0;
    list-style: none;
}
button{
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 10px;
    color: #D9D9D9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Colus";
}

button:hover{
    transform: scale(1.1);
}

a{
    text-decoration: none;
    color: #D9D9D9;
}
.flex{
    display: flex;
}
.col{
    flex-direction: column;
}
.wrap{
    flex-wrap: wrap;
}
.center{
    justify-content: center;
    align-items: center;
}

.image-visible {
    opacity: 1;
  }
.image-hidden {
    opacity: 0;
    display: none;
  }



