.blog-bg{
    width: 100%;
    height: 697px;
    background: url('/src/assets/images/backgrounds/blog_bg.png') no-repeat ;
    background-size: cover;
}
.blog-bg section{
    line-height: 3em;
}
.blog-bg h1{
    font-weight: 400;
    font-size: 24px;
    color: #A6A28D;
}
.blog-bg h3{
    font-weight: 400;
    font-size: 16px;
    color: #D9D9D9;
}
.blog-bg section div{
    margin-top: 4em;
}
.blog input{
    width: 450px;
    max-width: 85%;
    height: 45px;
    border: none;
    background: rgba(3, 2, 3);
    border-radius: 10px;
    color: #CBD7D9;
    padding: 0 2em;
    margin-bottom: 1rem;
    transform: skew(-10deg);
    font-size: 14px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}
.blog input:focus{
    outline: #d9d9d9 solid 1px;
}
.blog-bg button, .blog-search button, .more button{
    width: 200px;
    height: 45px;
    background: rgba(3, 2, 3);
    transform: skew(-10deg);
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-size: 14px;
}
/* --------- */
.arts-card{
    width: 90%;
    min-height: 100%;
    background: linear-gradient(180.4deg, #0d1315b0 0.34%, #000000 98.07%);
    box-shadow: 0px 6.36399px 3.18199px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    border-radius: 10px;
    transform: translateY(-120px);
}
.arts-cont{
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('/src/assets/images/misc/circle.svg') no-repeat center;
    background-size: 75% 75%;
    opacity: 0.4;
    z-index: 0;
}
.arts-card section, .article section{
    width: 85%;
    height: 100%;
    margin: 0 auto;
    padding: 4em 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.blog-search input{
    width: 710px;
}
.bl_ls{
    position: relative;
    width: 1000px;
}
.bl-rs{
    position: relative;
    width: 400px;
}
.bl_ls ul li{
    position: relative;
    width: 930px;
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin: 2em 0;

}
.bl_ls ul li img{
    width: 240px;
    height: 240px;
}
.bl_ls ul li section{
    width: 100%;
    padding: 2em 4em;
}
.bl_ls ul li section div h6{
    float: right;
}
.bl_ls ul li section div img{
    width: 15px;
    height: 15px;
    margin: 0 1.5em;
}
.bl_ls ul li h2{
    margin: 1.5em 0;
    font-size: 16px;
    color: #A6A28D;
}
.bl_ls ul li p{
    line-height: 2em;
    font-family: 'Montserrat';
    font-weight: bolder;
}
.bl_ls ul li p::after{
    content: '...';
}
.bl_ls ul li:hover{
    border: 1px solid;
    border-image: linear-gradient(to left, rgba(0, 0, 0, 1) 1%, #C1BEBB 50%, rgba(62, 61, 60, 0) 100%) 100% 0 100% 0/2px 0 2px 0 stretch;
}
.bl-rs h3{
    color: #A6A28D;
}
.rs-soc ul li a{
    display: flex;
    align-items: center;
    margin: 3em 0;
    font-family: Montserrat;
}
.rs-soc ul li img{
    width: 24px;
    margin-right: 2em;
}
.rs-soc ul li span{
    color: #D9D9D9;
}
.rs-soc ul li:hover span{
    font-weight: bolder;
    color: #fff;
}
.rs-ba{
    margin-top: 7em;
}
.rs-ba ul li section{
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
}
.rs-ba ul li{
    margin: 4em 0;
}
.rs-ba ul li section{
    margin-top: 1.5em;
}
.rs-ba ul li section img{
    width: 50px;
    height: 50px;
    margin-right: 1em;
}
.rs-ba ul li section p{
    font-size: 12px;
   font-family: Montserrat;
   line-height: 1.7em;
}
.rs-ba ul li div h6 img,.more-art ul li h6 img{
    margin: 0 1.5em;
}
.more{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.more ul li{
    margin: 0em 1em 3em;
}
.more button img{
    transform: scale(1.5);
    height: 100%;
}
/* ARTICLE */
.article{
    width: 100%;
    background: url('/src/assets/images/misc/circle.svg') no-repeat top;
    background-size: 80% 50%;
}
.art-bg{
    width: 100%;
    height: 100%;
    background: linear-gradient(180.4deg, #0d1315d0 0.34%, #000000 98.07%);
    box-shadow: 0px 6.36399px 3.18199px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    border-radius: 10px;
}
.img-sec{
    max-width: 75%;
    height: 400px;
    align-items: flex-end;
    margin: 0 auto;
}
.img-sec img{
    width: 590px;
    max-width: 100%;
    height: 320px;

}
.article .bl_ls h2{
    font-weight: 400;
    font-size: 24px;
    color: #A6A28D;
    margin-bottom: 1.5em;
}
.article .bl_ls p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    margin: 1em 0;
}
.article .bl_ls h4{
    font-weight: bolder;
    color: #A6A28D;
    margin: 2em 0;
}
.article .bl-rs {
    padding: 4em 0em 4em 6em;
    background: linear-gradient(180.4deg, rgba(13, 19, 21, 0.534) 0.34%, #000000 98.07%);
    box-shadow: 0px 6.36399px 3.18199px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.article .more-art{
    padding: 0;
}
.art-nav{
    width: 100%;
    justify-content: space-between;
}
.art-nav button{
    font-weight: 400;
    font-size: 14px;
    color: #D9D9D9;
}
.art-nav button:nth-child(1) img{
    margin-right: 1em;
    transform: rotateZ(180deg);
}
.art-nav button:nth-child(2) img{
    margin-left: 1em;
}
.more-art ul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 3em 0;
}
.more-art ul li{
    flex-grow: 1;
    max-width: 500px;
    margin: 1em 0;
}
.more-art ul li img{
    width: 150px;
    height: 150px;
    margin-right: 2em;
}
.more-art ul li h6 img{
    width: 15px;
    height: 11.25px;
    margin: 0 0.5vw;
}
.more-art h2{
    font-size: 16px;
    margin-top: 2em;
    color: #A6A28D;
}
/* MOBILE */
@media screen and (max-width:992px){
    .blog-bg{
        background-position: center;
    }
    .bl-rs{
        padding: 2em;
        width: 90vw;
    }
    .rs-soc ul{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .rs-soc ul li a{
        margin: 2em 0;
    }
    .rs-soc ul li{
        flex-grow: 1;
    }
    .rs-ba{
        margin-top: 4em;
    }
}
