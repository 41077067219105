.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.modal_bg{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.modal_cont{
    position: relative;
    width: 400px;
    background: #000;
    z-index: 5;
    border-radius: 10px;
    animation: modal 0.3s ease;
    color: #fff;
}

.modal_cont ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-bottom: .2vh solid #888;
    transition: all 200ms ease-in-out;
    border-radius: 10px;
    width: 100%;
    line-height: 3rem;
    padding: 10px 0;
    
}
.modal_cont ul li:last-child{
    border: none;
}
.modal_cont ul li img{
    width: 10vh;
    height: 10vh;
    transform: scale(1.1);
}
.modal_cont h2{
    font-weight: bold;
    font-size: 20px;
}
.modal_cont p{
    font-weight: bold;
    color: #888;
    font-size: 12px;
}
.modal_cont ul li:hover{
    background-color: #fff;
    background: linear-gradient(180deg, #20283F 0%, #2B5882 100%);

}


@keyframes modal {
    from{
        transform: scale(0.5);
    }
    to{
        transform: scale(1);
    }
}
