@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: "Colus";
  src: url("/src/assets/fonts/Colus/Colus-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  src: url("/src/assets/fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/src/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "L'Elf Noir du Mal";
  src: url("/src/assets/fonts/lelf_noir_du_mal/LELFNOIR.ttf") format("truetype");
  font-weight: normal;
}