.swiper {
  width: 100%;
  height: 100%;
}
.swiper-button-next, .swiper-button-prev{
  color:#fff
}
.swiper-pagination-bullet-active{
  background-color: #fff;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

  -----------
  .sec.d ul{
    width: 100%;
    display: flex;
    margin-top: 5em;
}
.creature-item{
    width: 320px;
    height: 767px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 2em 0;
    border-radius: 10px;
    line-height: 2em;
    text-align: center;
    opacity: 0.2;
}
.creature-item p{
    color: #A6A28D;
    white-space: pre-wrap;
}
.creature-item span{
    color: #A6A28D;
}
.creature-item img, .creature-item video{
    transform: scale(0);
    width: 300px;
    height: 300px;
    pointer-events: none;

}
.creature-item:hover{
    opacity: 1;
    transform: translateY(-50px);
}
.creature-item:hover img, .creature-item:hover video{
    transform: scale(1);
}
.creature-item:hover p{
    color: #fff;
}
  /* ---------- */