.nav{
    width: 100vw;
    height: 60px;
}
.nav-cont{
    width: 95%;
    height: 100%;
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo{
    text-align: center;
    width: 200px;
    height: 75px;
    background: #030203;
    box-shadow: 0px 0px 30px rgba(217, 217, 217, 0.25);
    border-radius: 4px;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.logo:before {
    border-top: 20px solid #030203;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    bottom: -19px;
    width: 0;
  }

.nav-buttons{
    display: flex;
    align-items: center;
}
.nav-buttons button{
    width: 240px;
    background: rgba(3, 2, 3, 0.8);
    border-radius: 10px;
}
.nav-buttons button, .nav-buttons a{
    height: 40px;
    margin: 0 2em;
    padding: 0 2em;
    border-bottom: 2px solid #3E3D3C;
    border-right: 2px solid #3E3D3C;
    border-left: 2px solid #3E3D3C;
	transform: skewX(-10deg);
    font-size: calc(0.7em + 0.1vw + 0.1vh);
    border-radius: 10px;
    color: #A6A28D;
    letter-spacing: 0.1em;
}

.nav-buttons button img{
    margin-right: 0.5em;
}

.nav-buttons a{
    border-radius: 10px;
    line-height: 40px;
    z-index: 2;
}

.nav-buttons a:hover{
    transform: scale(1.1);
}

.nav-buttons .menu{
    width: 40px;
    height: 40px;
	transform: none;
    display: none;
}
.nav-buttons .menu img{
    margin-right: 0;
}

/* MOBILE */
@media screen and (max-width:992px){
    .nav{
        padding: 2em 0;
    }
    .logo{
       display: none;
    }
    .nav-buttons{
        margin: 0 auto;
        width: 100%;
        justify-content: center;
    }
    .nav-buttons > button{
        flex-grow: 1;
        position: relative;
        z-index: 2;

    }
    .nav-buttons .menu {
        display:flex;
    }
}